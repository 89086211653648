
"use strict";
jQuery(function($) {

    window.cookieconsent.initialise({
        palette: {
            "popup": {
                "background": "#ffffff",
                "text": "#39302b",
            },
            "button": {
                "background": "#e8ded5",
                "text": "#444444",
            },
        },
        theme: "classic",
        content: {
            "message": "Lieber Besucher, unsere Seite verwendet Cookies zur Speicherung Ihrer Einstellungen und anderer Informationen. Mehr über Cookies und ihre Verwendung erfahren Sie in unserer Datenschutzerklärung. Klicken Sie OKAY um fortzufahren. ",
            "dismiss": "OKAY",
            "link": "Mehr Infos",
            "href": "#Privacy",
        },
    });

    var offset = $('.Navigation').css('margin-top').replace('px', '');
    
    var animateInterval = setInterval(function() {
        var viewportWidth = $(window).innerWidth();
        var viewportHeight = $(window).innerHeight();
        var scrolled = $(window).scrollTop();        
        var factor = scrolled * 0.0020;
        
        window.requestAnimationFrame(function() {
            if (scrolled < viewportHeight / 3) $('.ScrollUp').hide();
            else $('.ScrollUp').show();

            if (viewportWidth < 768) return;

            if (scrolled >= offset) {
                $('.Navigation').addClass('Navigation__Fixed');
            } else {
                $('.Navigation').removeClass('Navigation__Fixed');
            }

            $('.Background__1').transition({ 
                y: '-' + (factor * 50) + 'px'
            }, 0);

            $('.AboveTheFold h1').transition({ 
                y: (factor * 250) + 'px',
                opacity:  (1- factor)
            }, 0);
        });

    }, 20);

    // Scroll to element
    function scrollTo(e, element) {
        e.preventDefault();
        
        var offset = $('.Navigation').outerHeight();
        var viewportWidth = $(window).innerWidth();
        var duration = viewportWidth > 768 ? 500 : 0;
        
        closeMenu(e);
        $('html, body').animate({
            scrollTop: $(element).offset().top - offset
        }, duration);
    }

    function toggleMenu(e) {
        e.preventDefault();
        $('.Slidemenu').toggleClass('Slidemenu__Open');
    }

    function closeMenu(e) {
        e.preventDefault();
        $('.Slidemenu').removeClass('Slidemenu__Open');        
    }

    $('.ScrollDown').click(function(e) { 
        scrollTo(e,'#Content'); 
    });

    $('.ScrollUp').click(function(e) { 
        scrollTo(e,'#Top'); 
    });

    $('.Navigation a:not(.openMenu), .Slidemenu a:not(.closeMenu)').each(function(index, element) {
        $(element).click(function(e) {
            scrollTo(e, $(element).attr('href'));
        });
    });

    $('.openMenu, .closeMenu').click(function(e) {
        toggleMenu(e);
    });

});